

.MuiOutlinedInput-multiline{
  color: black !important;
}

.input-container{
  margin:10px 0px 10px 0px; 
  background-color:white;
  border-radius:10px; 
  padding:10px;
}