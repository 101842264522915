/* .header-section {
  color: white;
} */

.header__content--left{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.header-section .Typewriter__wrapper{
  /* color: #22FD38; */
  color: #22FD38;
  font-weight: bold;
  font-size: 40px;
}
.header-section .Typewriter__cursor{
  color: #F52757;
  font-weight: bold;
  font-size: 40px;
}

.header__button{
  padding: 10px 90px 10px 90px !important;
}

.header__img--container{
  height: 300px;
  width: 300px;
  padding: 25px;
  border: 5px solid #F52757;
  /* border-bottom: 5px solid #f5275700;
  border-top: 5px solid #f5275700;
  border-right: 5px solid #f5275700; */
  
  border-radius: 100%;
  z-index: 0;
}
@media (prefers-reduced-motion: no-preference) {
  .header__img--container {
    animation: container-spin infinite 15s linear;
  }
  .header__img {
    animation: image-stop-spin infinite 15s linear;
  }
}



.header__img{
  height: 100%;
  border-radius: 100%;
  z-index: 1;
}

@media(min-width: 500px){
  .header__img--container{
    height: 500px;
    width: 500px;
    padding: 50px;
    border: none;
    border-left: 5px solid #F52757;
  }
  @keyframes container-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes image-stop-spin {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .header__content--left{
    align-items: flex-center;
  }
  .header-container{
    margin-top: 85px;
  }
  .header-title h1{
    font-size: 70px;
  }
  .header-section .Typewriter__wrapper{
    font-size: 60px;
  }
  .header-section .Typewriter__cursor{
    font-size: 60px;
  }
  
  .header__button a.activeButton{
    font-size: 40px;
  }
  .header__button a{
    font-size: 40px;
  }
  .header__button a:hover{
    font-size: 40px;
  }
}

.header-social{
  width: 33% !important;
}

.header__icon--container{
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 3em;
  color: rgba(248, 248, 248, 0.664);
  text-decoration: none;
  border: none;
  margin: 10px;
}
.header__icon--container:hover{
  color: white;
  text-decoration: none;
}

.header__button a.activeButton{
  color: black !important;
}
.header__button a{
  color: black !important;
}
.header__button a:hover{
  color: black !important;
}

