/* myComponent.css */

.myBubbleUI {
	width: 100%;
	max-width: 100%;
	height: 500px;
	border-radius: 50px;
}

.child {
	width: 80px;
	height: 80px;
	border-radius: 50%;
}